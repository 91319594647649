var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { FILTERS_EQUAL, MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL, VERIFY_PERMISSION, DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "britestack-general-info p-5" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "sm": 24, "md": 8 } }, [_c("a-card", [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 20 } }, [_c("div", { staticClass: "h4 neutral-10--text" }, [_vm._v(_vm._s(_vm.editForm.bsItemCode))]), _c("div", { staticClass: "b7 neutral-10--text" }, [_vm._v(_vm._s(_vm.editForm.itemDescription))])]), _c("a-col", { attrs: { "span": 4 } }, [_c("switch-input", { attrs: { "disabled": "", "label": "Trial", "value": _vm.editForm.trialFlag } })], 1), _c("a-col", { staticClass: "mt-4", attrs: { "span": 24 } }, [_c("text-input", { attrs: { "disabled": "", "label": "List Catalog", "value": _vm.editForm.listCatalog } })], 1), _c("a-col", { staticClass: "mt-5", style: { minHeight: "55px" }, attrs: { "span": 24 } }, [_c("div", { staticClass: "neutral-7--text" }, [_vm._v(" Last changed "), _vm.editForm.updatedBy ? _c("span", [_vm._v("by")]) : _vm._e(), _c("br"), _vm.editForm.updatedBy ? _c("span", [_vm._v(_vm._s(_vm.entity.updatedBy))]) : _vm._e(), _vm._v(" on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY HH:mm")) + " ")])])], 1)], 1)], 1), _c("a-col", { attrs: { "sm": 24, "md": 16 } }, [_c("a-card", [_c("a-row", { staticClass: "mb-5", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "sm": 12, "md": 8 } }, [_c("switch-input", { attrs: { "label": "Contain Meat", "value": _vm.editForm.containsMeat, "disabled": !_vm.canMaintain }, on: { "change": function($event) {
    return _vm.storeValue("containsMeat", $event);
  } } })], 1), _c("a-col", { attrs: { "sm": 12, "md": 16 } }, [_c("a-row", { attrs: { "align": "bottom", "gutter": 0, "type": "flex" } }, [_c("a-col", { attrs: { "span": 4 } }, [_vm.editForm.trialFlag ? _c("switch-input", { attrs: { "label": "PD Verified", "value": _vm.editForm.verifyFlag, "disabled": !_vm.canVerifyTrial }, on: { "change": function($event) {
    return _vm.storeValue("verifyFlag", $event);
  } } }) : _vm._e()], 1), _c("a-col", { attrs: { "span": 20 } }, [_vm.editForm.verifyFlag ? _c("span", { staticClass: "success-7--text" }, [_vm._v("Sterilisation Verified by PD")]) : _vm._e()])], 1)], 1)], 1), _c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "mb-3", attrs: { "sm": 24, "md": 8 } }, [_c("select-input", { attrs: { "advanced-filters": _vm.canSizesAdvancedFilters, "delete-filter": "IsInactive", "label": "Can Size", "lazy": "", "reference": "sterilization.common.can-sizes", "source": "id", "source-label": "name", "value": _vm.editForm.canSizeId, "disabled": !_vm.canMaintain }, on: { "change": function($event) {
    return _vm.storeValue("canSizeId", $event);
  } } })], 1), _c("a-col", { staticClass: "mb-3", attrs: { "sm": 24, "md": 8 } }, [_c("select-input", { attrs: { "label": "Fill Type", "lazy": "", "reference": "sterilization.common.fill-types", "source": "id", "source-label": "name", "value": _vm.editForm.fillTypeId, "disabled": !_vm.canMaintain }, on: { "change": function($event) {
    return _vm.storeValue("fillTypeId", $event);
  } } })], 1), _c("a-col", { staticClass: "mb-3", attrs: { "sm": 24, "md": 8 } }, [_c("number-input", { attrs: { "label": "Fill Weight", "value": _vm.editForm.fillWeight, "disabled": !_vm.canMaintain }, on: { "change": function($event) {
    return _vm.storeValue("fillWeight", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("text-area", { attrs: { "label": "Notes", "max-length": 150, "value": _vm.editForm.notes, "disabled": !_vm.canMaintain }, on: { "change": function($event) {
    return _vm.storeValue("notes", $event);
  } } })], 1)], 1)], 1)], 1)], 1), _c("a-divider"), _c("a-row", { attrs: { "gutter": 0, "justify": "end", "type": "flex" } }, [_vm.canMaintain ? _c("a-button", { on: { "click": _vm.onCancelClick } }, [_vm._v("Cancel")]) : _vm._e(), _vm.canMaintain ? _c("a-button", { staticClass: "ml-2", attrs: { "loading": _vm.isSubmitting, "type": "primary" }, on: { "click": _vm.onSaveClick } }, [_vm._v("Save")]) : _vm._e()], 1)], 1);
};
var staticRenderFns$4 = [];
const __vue2_script$4 = {
  name: "BritestackGeneralInformation",
  inject: ["apiUrl", "crud", "redirectRoute", "can"],
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      canSizesAdvancedFilters: [
        ["Facility", { operator: FILTERS_EQUAL, value: "PK,PP,PT" }]
      ],
      editForm: {},
      isSubmitting: false
    };
  },
  computed: {
    canMaintain() {
      if (this.can(MAINTAIN_PERMISSION))
        return true;
      if (this.$can(MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL) && this.entity.trialFlag)
        return true;
      return false;
    },
    canVerifyTrial() {
      return this.$can(VERIFY_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL);
    }
  },
  watch: {
    entity(newVal) {
      this.storeValues(newVal);
    }
  },
  mounted() {
    this.storeValues(this.entity);
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = __spreadProps(__spreadValues({}, this.editForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("editForm", { resourceKey, value });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    onCancelClick() {
      this.$router.push(this.redirectRoute);
    },
    async onSaveClick() {
      this.isSubmitting = true;
      try {
        await this.crud.submitEntity("replace");
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var BritestackGeneralInfo = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "custom-url": _vm.customUrl, "hide-pagination": "", "row-key": "steriAttachId" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.delete) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mr-2", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDetailClick(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "Method", attrs: { "base-url": "/sterilization-specifications/data-entries", "data-index": "method", "id-prop-name": "steriId", "sorter": true, "title": "Method Type", "query": _vm.query } }), _c("text-field", { key: "Code", attrs: { "data-index": "code", "title": "Code", "align": "left", "sorter": true } }), _c("text-field", { key: "StackingPattern", attrs: { "data-index": "stackingPattern", "title": "Stacking Pattern", "align": "left", "sorter": true } }), _c("text-field", { key: "EffDate", attrs: { "align": "left", "data-index": "effDate", "parse": _vm.parseDate, "sorter": true, "title": "Effective Date" } }), _c("text-field", { key: "EndDate", attrs: { "align": "left", "data-index": "endDate", "parse": _vm.parseDate, "sorter": true, "title": "End Date" } })], 1);
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "ViewAttachedSterilisations",
  inject: ["editRoute", "crud", "can"],
  props: {
    britestackId: {
      type: Number,
      required: true
    },
    customUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      permissions: {
        delete: DELETE_PERMISSION
      }
    };
  },
  computed: {
    query() {
      const britestack = this.$store.getters["sterilization.britestacks/byId"](this.britestackId);
      if (!britestack)
        return null;
      return {
        bs: this.britestackId,
        bsCode: britestack.bsItemCode,
        redirect: this.$route.name
      };
    }
  },
  methods: {
    parseDate(value) {
      if (!value) {
        return "";
      }
      return this.$moment(value).format("DD/MM/YYYY");
    },
    onDetailClick(record) {
      let url = this.editRoute.replace(":id", record.steriId);
      if (this.query) {
        const { bs, bsCode, redirect } = this.query;
        url += `?bs=${bs}&bsCode=${bsCode}&redirect=${redirect}`;
      }
      this.$router.push(url);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ViewAttachedSterilisations$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "page": _vm.page, "name": "sterilization.britestacks.attached-sterilizations", "api-url": _vm.apiUrl, "edit-route": "/sterilization-specifications/data-entries/:id", "redirect-route": "/sterilization-specifications/britestacks", "resource-id-name": "steriAttachId" } }, [_c("view-attached-sterilisations", { attrs: { "britestack-id": _vm.britestackId, "custom-url": _vm.customUrl } })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    ViewAttachedSterilisations: ViewAttachedSterilisations$1
  },
  props: {
    britestackId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      page: STERILIZATION_SPECIFICATIONS_BRITESTACK,
      apiUrl: apiUrl$1,
      customUrl: `${apiUrl$1}/sterilization/britestacks/${this.britestackId}/attached-sterilizations`
    };
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ViewAttachedSterilisations = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-britestack" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("a-row", { staticClass: "py-2 px-5 neutral-1", attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "d-flex align-items-center", attrs: { "span": 16 } }, [_c("a-icon", { staticClass: "h5 mr-3", attrs: { "type": "arrow-left" }, on: { "click": _vm.onBackClick } }), _c("div", { staticClass: "h5 mr-3" }, [_vm._v(_vm._s(_vm.entity.bsItemCode))]), _c("div", { staticClass: "b7 neutral-7--text" }, [_vm._v(_vm._s(_vm.entity.itemDescription))])], 1), _c("a-col", { staticClass: "d-flex align-items-center justify-content-end", attrs: { "span": 8 } }, [_vm.canToggleActive ? [_vm.entity.isInactive ? _c("a-button", { attrs: { "ghost": "", "type": "primary" }, on: { "click": _vm.onToggleActiveClick } }, [_vm._v(" Activate ")]) : _c("a-button", { attrs: { "ghost": "", "type": "danger" }, on: { "click": _vm.onToggleActiveClick } }, [_vm._v(" Deactivate ")])] : _vm._e(), _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary" }, on: { "click": _vm.onViewFinishedProductsClick } }, [_vm._v(" View Finished Products ")])], 2)], 1), _c("a-tabs", { staticClass: "neutral-1", on: { "change": _vm.onTabChange }, model: { value: _vm.activeTabKey, callback: function($$v) {
    _vm.activeTabKey = $$v;
  }, expression: "activeTabKey" } }, [_c("a-tab-pane", { key: "1", attrs: { "tab": "General Information" } }, [_c("britestack-general-info", { attrs: { "entity": _vm.entity } })], 1), _c("a-tab-pane", { key: "2", attrs: { "tab": "View Attached Sterilisations (" + _vm.entity.noOfAttachment + ")" } }, [_c("view-attached-sterilisations", { attrs: { "britestack-id": _vm.britestackId } })], 1)], 1), _c("router-view")], 1);
};
var staticRenderFns$1 = [];
var EditBritestack_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditBritestack",
  components: {
    BritestackGeneralInfo,
    ViewAttachedSterilisations
  },
  inject: ["crud", "redirectRoute", "resourceName", "can"],
  props: {
    id: {
      type: Number,
      required: true
    },
    tab: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTabKey: this.tab,
      britestackId: this.id,
      entity: {},
      permissions: {
        delete: DELETE_PERMISSION,
        maintain: MAINTAIN_PERMISSION,
        verify: VERIFY_PERMISSION
      }
    };
  },
  computed: {
    canToggleActive() {
      if (this.can(DELETE_PERMISSION))
        return true;
      if (this.$can(DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL) && this.entity.trialFlag)
        return true;
      return false;
    },
    isUpdateAttachSte() {
      return this.$store.state["sterilization.britestacks.attached-sterilizations"].isUpdating;
    },
    menuItems() {
      const menuItems = [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "britestack",
          title: "Britestack",
          path: "/sterilization-specifications/britestacks"
        }
      ];
      if (this.entity) {
        const { bsItemCode, id } = this.entity;
        return [
          ...menuItems,
          {
            key: bsItemCode,
            title: bsItemCode,
            path: `/sterilization-specifications/britestacks/${id}`
          }
        ];
      }
      return menuItems;
    }
  },
  watch: {
    isUpdateAttachSte(val) {
      if (!val) {
        this.getData();
      }
    }
  },
  async created() {
    this.activeTabKey = this.tab;
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.crud.fetchEntity();
      const { data } = res;
      if (data) {
        this.britestackId = data.id;
        this.entity = data;
      }
    },
    async toggleActive() {
      await this.crud.submitPartialEntity({
        resourceName: this.resourceName,
        id: this.entity.id,
        data: [
          {
            op: "replace",
            path: "/IsInactive",
            value: !this.entity.isInactive
          }
        ]
      });
      this.$router.push(this.redirectRoute);
    },
    onBackClick() {
      this.$router.push(this.redirectRoute);
    },
    onTabChange(activeKey) {
      this.$router.replace(`${this.$route.path}?tab=${activeKey}`);
    },
    onToggleActiveClick() {
      let icon, okType, title, content = `${this.entity.noOfAttachment} sterilisations is attached to ${this.entity.bsItemCode}`;
      if (this.entity.isInactive) {
        title = `Do you want to activate ${this.entity.bsItemCode}?`;
        icon = "eye";
        okType = "primary";
      } else {
        title = `Do you want to deactivate ${this.entity.bsItemCode}?`;
        icon = "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        cancelText: "No",
        content,
        icon,
        okText: "Yes",
        okType,
        title,
        onOk: this.toggleActive
      });
    },
    onViewFinishedProductsClick() {
      this.$router.push(`/sterilization-specifications/britestacks/${this.entity.id}/finished-products`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "ea639268", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditBritestack = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.fill-types", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.britestacks", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/britestacks" } }, [_c("edit-britestack", { attrs: { "id": _vm.id, "tab": _vm.tab } })], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditBritestack
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    tab: {
      type: String,
      default: "1"
    }
  },
  data() {
    return {
      page: STERILIZATION_SPECIFICATIONS_BRITESTACK,
      apiUrl,
      menuItems: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "britestack",
          title: "Britestack",
          path: "/sterilization-specifications/britestacks"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
